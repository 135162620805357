
import { computed, defineComponent } from 'vue'
import Line from '@/components/Line.vue'
import Pic from '@/components/Pic.vue'
import { mul } from 'essential/tools/math'

export default defineComponent({
  name: 'TheRankListItem',
  components: { Pic, Line },
  props: {
    list: {
      type: Object,
      required: true,
    },
    participate: Boolean,
  },
  setup (props) {
    const profitRate = computed(() => mul(props.list.profitRate, 100))
    return {
      profitRate,
    }
  },
})
