import requestResult from '@/common/request/request'

export type RankListSchema = {
  profitRate: number;
  nickName: string;
  rankNum: number;
  prize: number;
  avatarPic: string;
}

export type TournamentSchema = {
  tournamentId: number;
  name: string;
  startPrize: number;
  participant: number;
  duration: string;
  startTime: number;
  endPrize: number;
  endTime: number;
  entryFee: number;
  status: number // 0:未开始 1：进行中 2：已结束
  joinLevel: number;
  tips: string;
  downTime: number;
  level: string;
  isJoin?: number;
  levelValue?: number;
}

export const readRankList = requestResult<Array<RankListSchema>>('/api/tournament/join/record')

export const readTournamentList = requestResult<Array<TournamentSchema>>('/api/tournament/list')

export const tournamentDetail = requestResult('/api/tournament/detail')

export const tournamentJoin = requestResult('/api/tournament/join')

export const isJoin = requestResult('/api/tournament/isjoin')
