
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent } from 'vue'
import Button from '@/components/Button.vue'
import { tournamentJoin } from '@/modules/membership/tournament/tournament.api'
import useRequest from '@/hooks/useRequest'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  name: 'ParticipateConfirmDialog',
  components: { Icon, DialogTemplate, Button },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    const [commit, progress] = useRequest(tournamentJoin)

    return {
      submit () {
        commit({
          tournamentId: props.info.tournamentId,
        }).then(() => {
          ctx.emit('close')
          ctx.emit('confirm')
        })
      },
      progress,
    }
  },
})
