import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "py-32 t-center",
  style: {"line-height":"24px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")
  const _component_t = _resolveComponent("t")
  const _component_Button = _resolveComponent("Button")
  const _component_DialogTemplate = _resolveComponent("DialogTemplate")

  return (_openBlock(), _createBlock(_component_DialogTemplate, { "no-title": "" }, {
    confirm: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "flex-1 primary",
        onClick: _ctx.submit,
        progress: _ctx.progress
      }, {
        default: _withCtx(() => [
          _createVNode(_component_t, {
            path: "tournamentdetail_14",
            custom: ""
          }, {
            default: _withCtx(({td}) => [
              _createTextVNode(_toDisplayString(td || 'OK'), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onClick", "progress"])
    ]),
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_Icon, {
          name: "check",
          class: "bg-success mb-20",
          style: {"font-size":"40px","color":"white","border-radius":"20px"}
        }),
        _createVNode(_component_t, {
          as: "p",
          class: "px-16",
          path: "tournamentdetail_12",
          args: {tournament: `${_ctx.info.name}`, points: `${_ctx.info.entryFee}`}
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Are you sure to participate in the " + _toDisplayString(_ctx.info.name) + "? You need to pay " + _toDisplayString(_ctx.info.entryFee) + " points. ", 1)
          ]),
          _: 1
        }, 8, ["args"])
      ])
    ]),
    _: 1
  }))
}